import {
  FILTERS_SET,
  FILTERS_RESET,
  FILTERS_INIT_DATA,
  FILTERS_ORDER,
  FILTERS_REMOVE_ITEMS,
  FILTERS_SERVICE_SET
} from './action-types'
import { getSharedCategories, getItemsCategories } from '../../../utils/helpers'

export function setFilters (filters) {
  return {
    type: FILTERS_SET,
    payload: filters
  }
}

export function setService (filters) {
  return {
    type: FILTERS_SERVICE_SET,
    payload: filters
  }
}

export function resetFilters () {
  return {
    type: FILTERS_RESET
  }
}

export function setOrder (order) {
  return {
    type: FILTERS_ORDER,
    payload: order
  }
}

export function removeItems () {
  return {
    type: FILTERS_REMOVE_ITEMS
  }
}

// sets initial data without filtering
export function initData ({ init: { hotels, tours, trips, countries, regions, locations, categories }, options }) {
  const hotelsCategories = getItemsCategories(categories, 'hotels')
  const toursCategories = getItemsCategories(categories, 'tours')
  const tripsCategories = getItemsCategories(categories, 'trips')
  const sharedCategories = getSharedCategories(categories)

  const foundHotels = hotels.length
  const foundTours = tours.length
  const foundTrips = trips.length
  const foundTotal = foundHotels + foundTours + foundTrips

  return {
    type: FILTERS_INIT_DATA,
    payload: {
      options,
      init: {
        hotels,
        tours,
        trips,
        countries,
        regions,
        locations,
        allCategories: categories,
        hotelsCategories,
        toursCategories,
        tripsCategories,
        sharedCategories,
        found: {
          total: foundTotal,
          hotels: foundHotels,
          tours: foundTours,
          trips: foundTrips
        }
      }
    }
  }
}
