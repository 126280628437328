import { TOGGLE_MODAL } from './action-types'

export const INITIAL_STATE = {
  open: false,
  modalId: '',
  data: {}
}

export function modalReducer (state = INITIAL_STATE, { payload, type }) {
  switch (type) {
    case TOGGLE_MODAL:
      return { open: !state.open, modalId: payload.id, data: payload.data }

    default:
      return state
  }
}
