import React from 'react'
import { t } from 'i18next'

export const getCountryOptions = (countries) => {
  return countries
    .map(({ node: { id, title, slug } }) => ({
      id,
      text: title,
      value: `${JSON.stringify({ countrySlug: slug, country: title })}`,
      content: <p>{title} <span>({t('finder.country')})</span></p>
    }))
}

export const getRegionOptions = (regions) => {
  return regions
    .map(({ node: { id, title, slug, country, countrySlug } }) => ({
      id,
      text: title,
      value: `${JSON.stringify({ countrySlug, country, region: title, regionSlug: slug })}`,
      description: `${country}`,
      content: <p>{title} <span>({t('finder.region')})</span></p>
    }))
}

export const getLocationOptions = (locations) => {
  return locations
    .map(({ node: { id, title, slug, country, countrySlug, region, regionSlug } }) => ({
      id,
      text: title,
      value: `${JSON.stringify({ countrySlug, country, region, regionSlug, location: title, locationSlug: slug })}`,
      description: `${country} / ${region}`,
      content: <p>{title} <span>({t('finder.location')})</span></p>
    }))
}

export const getFinderOptions = (countries, regions, locations) => {
  return [...getCountryOptions(countries), ...getRegionOptions(regions), ...getLocationOptions(locations)]
}
