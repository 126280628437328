import {
  FEEDBACK_SET_STEP,
  FEEDBACK_SET_ANSWER,
  FEEDBACK_SET_RATING,
  FEEDBACK_SET_COMMENT,
  FEEDBACK_SET_LIKES,
  FEEDBACK_SET_META
} from './action-types'

const initialState = {
  currentIndex: 0,
  answer: {}
}

export function feedbackReducer (state = initialState, { payload, type }) {
  switch (type) {
    case FEEDBACK_SET_STEP:
      return { ...state, currentIndex: payload }

    case FEEDBACK_SET_ANSWER:
      return {
        ...state,
        answer: {
          ...state.answer,
          [payload.step]: {
            ...(state.answer[payload.step] || {}),
            [payload.id]: {
              ...((state.answer[payload.step] || {})[payload.id]),
              [payload.type]: payload.value
            }
          }
        }
      }

    case FEEDBACK_SET_META:
      return {
        ...state,
        answer: {
          ...state.answer,
          dossierNumber: payload.dossierNumber,
          employeeCode: payload.employeeCode,
          travellers: payload.travellers
        }
      }

    case FEEDBACK_SET_RATING:
      return {
        ...state,
        answer: {
          ...state.answer,
          [payload.step]: {
            ...(state.answer[payload.step] || {}),
            [payload.id]: {
              ...((state.answer[payload.step] || {})[payload.id]),
              serviceName: payload.serviceName,
              beginDate: payload.beginDate,
              endDate: payload.endDate,
              destCode: payload.destCode,
              serviceCode: payload.serviceCode,
              rating: payload.value
            }
          }
        }
      }

    case FEEDBACK_SET_COMMENT:
      return {
        ...state,
        answer: {
          ...state.answer,
          [payload.step]: {
            ...(state.answer[payload.step] || {}),
            [payload.id]: {
              ...((state.answer[payload.step] || {})[payload.id]),
              serviceName: payload.serviceName,
              beginDate: payload.beginDate,
              endDate: payload.endDate,
              destCode: payload.destCode,
              serviceCode: payload.serviceCode,
              comment: payload.value
            }
          }
        }
      }

    case FEEDBACK_SET_LIKES:
      return {
        ...state,
        answer: {
          ...state.answer,
          [payload.step]: {
            ...(state.answer[payload.step] || {}),
            [payload.id]: {
              ...((state.answer[payload.step] || {})[payload.id]),
              serviceName: payload.serviceName,
              beginDate: payload.beginDate,
              endDate: payload.endDate,
              likes: payload.value
            }
          }
        }
      }

    default:
      return state
  }
}
