import {
  FEEDBACK_SET_STEP,
  FEEDBACK_SET_RATING,
  FEEDBACK_SET_COMMENT,
  FEEDBACK_SET_LIKES,
  FEEDBACK_SET_ANSWER,
  FEEDBACK_SET_META
} from './action-types'

export function setStep (index) {
  return {
    type: FEEDBACK_SET_STEP,
    payload: index
  }
}

export function setAnswer ({ step, id, value, type }) {
  return {
    type: FEEDBACK_SET_ANSWER,
    payload: { step, id, value, type }
  }
}

export function setMeta ({ dossierNumber, employeeCode, travellers }) {
  return {
    type: FEEDBACK_SET_META,
    payload: { dossierNumber, employeeCode, travellers }
  }
}

export function setRating ({ step, id, serviceName, beginDate, endDate, destCode, serviceCode, value }) {
  return {
    type: FEEDBACK_SET_RATING,
    payload: { step, id, serviceName, beginDate, endDate, destCode, serviceCode, value }
  }
}

export function setLikes ({ step, id, serviceName, beginDate, endDate, destCode, serviceCode, value }) {
  return {
    type: FEEDBACK_SET_LIKES,
    payload: { step, id, serviceName, beginDate, endDate, destCode, serviceCode, value }
  }
}

export function setComment ({ step, id, serviceName, beginDate, endDate, destCode, serviceCode, value }) {
  return {
    type: FEEDBACK_SET_COMMENT,
    payload: { step, id, serviceName, beginDate, endDate, destCode, serviceCode, value }
  }
}
