import { SET_SUB_NAVIGATION, SET_LIGHTBOX, TOGGLE_MOBILE_MENU, TOGGLE_MOBILE_FILTERS } from './action-types'

export function toggleMobileMenu () {
  return {
    type: TOGGLE_MOBILE_MENU
  }
}

export function toggleMobileFilters () {
  return {
    type: TOGGLE_MOBILE_FILTERS
  }
}

export function setSubNavigation ({ subMenuName }) {
  return {
    type: SET_SUB_NAVIGATION,
    payload: { subMenuName }
  }
}

export function setLightbox ({ image, open }) {
  return {
    type: SET_LIGHTBOX,
    payload: { image, open }
  }
}
