import { WISHLIST_ADD_ITEM, WISHLIST_REMOVE_ITEM, WISHLIST_REMOVE_ALL } from './action-types'

export function wishlistAddItem (item) {
  return {
    type: WISHLIST_ADD_ITEM,
    payload: item
  }
}

export function wishlistRemoveItem (slug) {
  return {
    type: WISHLIST_REMOVE_ITEM,
    payload: slug
  }
}

export function wishlistRemoveAll (slug) {
  return {
    type: WISHLIST_REMOVE_ALL
  }
}
