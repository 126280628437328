import { TOGGLE_MOBILE_MENU, TOGGLE_MOBILE_FILTERS, SET_SUB_NAVIGATION, SET_LIGHTBOX } from './action-types'

const initialState = {
  subMenuName: '',
  mobileMenuActive: false,
  mobileFiltersActive: false,
  options: {
    hotels: [],
    trips: [],
    tours: [],
    countries: [],
    regions: [],
    locations: []
  },
  lightbox: {
    open: false,
    image: ''
  },
  lang: 'de'
}

export function navigationReducer (state = initialState, { payload, type }) {
  switch (type) {
    case TOGGLE_MOBILE_MENU:
      return { ...state, mobileMenuActive: !state.mobileMenuActive }
    case TOGGLE_MOBILE_FILTERS:
      return { ...state, mobileFiltersActive: !state.mobileFiltersActive }
    case SET_SUB_NAVIGATION:
      return { ...state, ...payload }
    case SET_LIGHTBOX:
      return { ...state, lightbox: payload }
    default:
      return state
  }
}
