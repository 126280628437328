exports.mobileBreakpoint = 320
exports.tabletBreakpoint = 768
exports.computerBreakpoint = 992
exports.largeMonitorBreakpoint = 1200
exports.widescreenMonitorBreakpoint = 1920
exports.HOSTNAME = 'https://localhost:8000/'
exports.LIVE_URL = 'https://www.tourasia.ch'

exports.dateFormat = 'DD.MM.YYYY'

exports.colorRed = '#ca003a'
exports.colorDarkRed = '#60003a'
exports.colorBlack = '#000000'
exports.colorWhite = '#ffffff'
exports.colorLightGray = '#ebebeb'
exports.colorGray = '#999999'
exports.colorGraySecond = '#cccccc'
exports.colorDarkGray = '#4d4d4d'

exports.links = {
  countries: { de: 'laender', fr: 'pays' },
  // place url parts
  all: { de: 'alles', fr: 'tout' },
  destinations: { de: 'reiseziele', fr: 'destinations' },
  regions: { de: 'regionen', fr: 'regions' },
  locations: { de: 'orte', fr: 'lieux' },
  // service groups
  tours: { de: 'rundreisen', fr: 'circuits' },
  hotels: { de: 'hotels', fr: 'hotels' },
  trips: { de: 'ausfluge', fr: 'excursions' },
  'all-services': { de: 'alle-dienstleistungen', fr: 'tous-les-services' },
  // dossier + feedback single pages
  dossier: { de: 'reisevorschlag', fr: 'proposition' },
  feedback: { de: 'feedback', fr: 'feedback' },
  'feedback-thanks': { de: 'feedback-thanks', fr: 'feedback-thanks' },
  // WP-generated single pages
  entry: { de: 'einreisebestimmungen', fr: 'formalites-dentree' },
  team: { de: 'team', fr: 'equipe' },
  catalog: { de: 'kataloge', fr: 'catalogues' },
  wishlist: { de: 'meine-reise', fr: 'mon-voyage' },
  contact: { de: 'kontakt', fr: 'contact' },
  offices: { de: 'offices', fr: 'offices' },
  privacy: { de: 'datenschutz', fr: 'protection-des-donnees' },
  // internal pages
  '404': { de: '404', fr: '404' },
}
