import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
// import LngDetector from 'i18next-browser-languagedetector';

// import myDetector from './langDetector'
// const lngDetector = new LngDetector();
// lngDetector.addDetector(myDetector);


const options = {
  fallbackLng: 'de',

  ns: ['translations'],
  defaultNS: 'translations',

  debug: false,

  interpolation: {
    escapeValue: false
  },

  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.json'
  },

  detection: {
    order: ['path'],
    lookupFromPathIndex: 0
  },

  react: {
    useSuspense: false
  }
}

if (process.browser) {
  i18n
    .use(Backend)
}


i18n
  .use(initReactI18next)
// .use(LngDetector)

if (!i18n.isInitialized) i18n.init(options)

export default i18n
