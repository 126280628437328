import { t } from 'i18next'
import fecha from 'fecha'

import { dateFormat } from './constants'

export const getLocaleMonths = () => ([
  t('calendar.january'),
  t('calendar.february'),
  t('calendar.march'),
  t('calendar.april'),
  t('calendar.may'),
  t('calendar.june'),
  t('calendar.july'),
  t('calendar.august'),
  t('calendar.september'),
  t('calendar.october'),
  t('calendar.november'),
  t('calendar.december')
])

export const getLocaleWeekLong = () => ([
  t('calendar.sunday'),
  t('calendar.monday'),
  t('calendar.tuesday'),
  t('calendar.wednesday'),
  t('calendar.thursday'),
  t('calendar.friday'),
  t('calendar.saturday')
])

export const getLocaleWeekShort = () => ([
  t('calendar.su'),
  t('calendar.mo'),
  t('calendar.tu'),
  t('calendar.we'),
  t('calendar.th'),
  t('calendar.fr'),
  t('calendar.sa')
])

export const getDate = (formattedDate, format = dateFormat) => {
  return fecha.parse(formattedDate, format)
}
