import { WISHLIST_ADD_ITEM, WISHLIST_REMOVE_ITEM, WISHLIST_REMOVE_ALL } from './action-types'

const initialState = {}

export function wishlistReducer (state = initialState, { payload, type }) {
  let newState

  switch (type) {
    case WISHLIST_ADD_ITEM:
      const item = { [payload.id]: { ...payload } }
      newState = { ...state, ...item }

      return newState

    case WISHLIST_REMOVE_ITEM:
      newState = { ...state }
      delete newState[payload]

      return newState

    case WISHLIST_REMOVE_ALL:
      console.log('remove all')
      return {}

    default:
      return state
  }
}
