import { combineReducers } from 'redux'

import { filtersReducer } from '../components/Filters/state'
import { wishlistReducer } from '../components/Wishlist/state'
import { modalReducer } from '../components/Modals/state'
import { navigationReducer } from '../components/Navigations/state'
import { feedbackReducer } from '../components/Feedback/state'

export default combineReducers({
  filters: filtersReducer,
  wishlist: wishlistReducer,
  modals: modalReducer,
  navigation: navigationReducer,
  feedback: feedbackReducer
})
